<template>
  <div
    class="item-product-type-wrapper"
    :style="{ flexDirection: orientation }">
    <div
      v-for="item in typesToRendering"
      :key="item.title"
      class="item-product-type-wrapper__item mt-2">
      <span
        :style="`background-color: ${item.color}`">
        {{ item.title }}
      </span>
    </div>
    <p
      v-if="typesLength > 4"
      class="item-product-type-wrapper__additional-type-count">
      ... + {{ typesLength - 3 }}
    </p>
  </div>
</template>
<script>
import json from '@/components/Product/ProductCell/ProductCellDropdownV2/customDropdownsColors';
import productHeaders from '@/constants/productHeaders';
export default {
  name: 'ElementProductType',
  props: {
    types: {
      type: Array,
      default: () => [],
    },
    orientation: {
      type: String,
      default: 'column',
    },
  },
  data() {
    return {
      json,
    };
  },
  computed: {
    checkedTypes() {
      const { types } = this;
      if (!Array.isArray(types) || !types) return [];
      return types;
    },
    limitedTypes() {
      const { checkedTypes: types } = this;
      return [...types.slice(0, 3)];
    },
    typesToRendering() {
      const { productTypeColorObj: obj, limitedTypes } = this;
      if (!limitedTypes.length) {
        return [];
      }
      let types = limitedTypes.map(item => {
        const itemLength = item.length;
        const colorNumber = itemLength <= 12 ? itemLength : itemLength % 12;
        return {
          title: item,
          color: obj.dropdownColors[colorNumber],
        };
      });
      return types;
    },
    typesLength() {
      const { checkedTypes: types } = this;
      return types.length;
    },
    productTypeColorObj() {
      return this.json.colors.find(el => el.string === productHeaders.PRODUCT_TYPE);
    },
  },
};
</script>
<style lang="scss" scoped>
  .item-product-type-wrapper {
    display: flex;
    flex-wrap: wrap;
    &__item {
      display: grid;
      justify-content: flex-start;
      margin-right: 8px;
      span {
        font-size: 14px;
        color: #FFFFFF;
        padding: 5px 20px;
        border-radius: 7px;
        /* The following CSS rule was added to address an issue where the height of the 'span' element was displaying as too large on Safari.
        The 'fit-content' value for the 'height' property helps ensure that the height of the 'span' element adjusts to fit its content. */
        height: fit-content;
      }
    }
    &__additional-type-count {
      display: flex;
      align-items: flex-end;
      font-weight: bold;
      font-size: 15px;
      white-space: nowrap;
      margin-bottom: 0;
    }
  }
</style>